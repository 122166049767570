
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url(../../assets/image/login/bg.png) center/100% 100% no-repeat;
  .logo {
    width: 70px;
    height: 70px;
    margin: 42px auto;
  }
  .item {
    width: 90%;
    background: #fff8f2;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #333333;
    .phone {
      width: 100%;
      height: 50px;
      background: #fff8f2;
      border-radius: 4px;
      padding: 0 12px;
    }
    .code {
      flex: 1;
      height: 50px;
      background: #fff8f2;
      padding-left: 12px;
    }
    .btn {
      height: 50px;
      color: #fc6c21;
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
    .btn-01 {
      color: #999999;
    }
  }
  .big-btn {
    margin-top: 45px;
  }
  .protocol {
    display: flex;
    align-items: flex-start;
    margin: 0 15px;
    .checkbox {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-top: 2px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .container {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      span {
        color: #fc6c21;
      }
    }
  }
}
.pop-box {
  width: 264px;
  height: 130px;
  .title {
    margin: 15px 0 25px 0;
  }
}
/deep/._nc .stage1 .bg-green {
  background: linear-gradient(90deg, #ff9d5b 0%, #fc6c21 100%);
}
/deep/._nc .icon-ok {
  color: #fc7930;
}
/deep/._nc .stage1 .slider {
  box-shadow: none;
}
.color_gray {
  color: #999 !important;
}
