
.page-order {
  .item {
    padding: 0 15px;
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 15px 0 15px;
    .hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      .number {
        font-size: 12px;
        color: #999999;
        line-height: 17px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: none;
        padding: 12px 0;
        .icon-copy {
          display: block;
          width: 12px;
          height: 12px;
          margin-left: 5px;
          background: url(../../assets/icons/icon-copy.png) center/100% 100%
            no-repeat;
        }
      }
      .status {
        font-size: 13px;
        color: #9fa6af;
        line-height: 16px;
        white-space: nowrap;
      }
      .status-01 {
        color: #fc6c21;
      }
      .status-02 {
        color: #ff2223;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin: 12px 0;
      height: 40px;
      text-align: start;
    }
    .price {
      font-size: 14px;
      font-weight: 500;
      color: #fc6c21;
      line-height: 20px;
    }
    .bt {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px;
      .btn {
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 12px;
        height: 24px;
        text-align: center;
        margin-left: 8px;
      }
      .btn-01 {
        border: 1px solid #fc6c21;
        color: #fc6c21;
      }
      .btn-02 {
        background: linear-gradient(270deg, #f30406 0%, #ff5b07 100%);
        color: #ffffff;
      }
      .btn-03 {
        background: linear-gradient(137deg, #ff9d5b 0%, #fc6c21 100%);
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        color: #ffffff;
      }
    }
  }
  /deep/ .van-cell {
    padding: 0 !important;
    background: none !important;
  }
}
